<template>
	<div>
		<!-- Page Header -->
		<div class="d-flex justify-space-between align-center mb-3">
			<h3 class="text-h4 font-weight-light">Leads Converted</h3>
			
			<div>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey"><v-icon>mdi-filter-outline</v-icon></v-btn>
				<v-btn icon :small="!$vuetify.breakpoint.lgAndUp" color="grey"><v-icon>mdi-cached</v-icon></v-btn>
			</div>
		</div>
		
		<!-- Page Body -->
		<div>
			<v-card>
				<v-card-text>
					<bar-chart chart-id="leads-converted-bar-chart" dataset-id-key="leads-converted-label" :labels="labels" :datasets="datasets" />
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
	import BarChart from '@/components/graphs/Bar.vue'
	
	export default {
		components: {
			BarChart
		},
		data: () => ({
			labels: [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
			],
			datasets: [
			{
				label: 'Leads',
				backgroundColor: '#f66d99',
				data: [40, 20, 22, 39, 10, 40, 39, 80, 40, 20, 12, 11]
			},
			{
				label: 'Quotations',
				backgroundColor: '#f99169',
				data: [40, 15, 20, 25, 10, 38, 35, 60, 20, 18, 10, 10]
			},
			{
				label: 'Orders',
				backgroundColor: '#9d87d7',
				data: [35, 10, 15, 25, 5, 35, 20, 40, 18, 15, 5, 9]
			}
			]
		}),
	}
</script>
